//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Parolni kiriting',
    },
    className: {
      type: [Boolean, String],
      required: true,
      default: false,
    },
    indexVal: {
      type: [String, Number],
      default: 1,
    },
  },
  data() {
    return {
      model: this.value,
    }
  },
  watch: {
    model(currentValue) {
      this.model = currentValue.replace(/\s/g, '').replace(/[А-Я-Ё]/gi, ' ')
      this.$emit('input', currentValue)
    },
  },
  methods: {
    toggleType(id) {
      const input = document.getElementById(id)
      if (input.type === 'password') {
        input.type = 'text'
      } else {
        input.type = 'password'
      }
    },
  },
}
