//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  required,
  email,
  minLength,
  maxLength,
  sameAs,
} from 'vuelidate/lib/validators'
import CodeInput from '~/components/form/CodeInput.vue'
import Password from '~/components/form/Password.vue'
export default {
  components: { CodeInput, Password },
  layout: 'auth',
  props: {
    refreshAfterLogin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      signature: '',
      random: Math.random(),
      step: 1,
      password: '',
      repassword: '',
      email: '',
      code: '',
      timer: 0,
      pending: false,
      backErrors: {
        email: false,
        code: false,
      },
    }
  },
  validations: {
    email: {
      required,
      email,
      maxLength: maxLength(64),
    },
    password: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(32),
    },
    repassword: {
      required,
      sameAsPassword: sameAs('password'),
    },
    code: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(6),
    },
  },
  watch: {
    email(newValue, oldValue) {
      this.backErrors.email = false
    },
    code(newValue, oldValue) {
      this.backErrors.code = false
    },
    step(val) {
      if (this.step === 2) {
        this.timer = 60
        this.countDownTimer()
      }
    },
  },

  methods: {
    async submitFirstStage() {
      if (this.pending) return
      this.pending = true
      this.$v.email.$touch()
      if (!this.$v.email.$anyError) {
        await this.$axios
          .$post('auth/password/reset/', {
            email: this.email,
          })
          .then((response) => {
            this.signature = response.signature
            this.step = 2
            this.$toast.success(
              `${this.$t('code_mailed')} ${this.email} ${this.$t(
                'code_mailed_continued_text'
              )}`
            )
          })
          .catch((error) => {
            console.dir(error)
          })
      }
      this.pending = false
    },
    async submitSecondStage() {
      if (this.pending) return
      this.pending = true
      this.$v.code.$touch()
      if (!this.$v.code.$anyError) {
        await this.$axios
          .$post('auth/password/reset/verify/', {
            code: this.code,
            signature: this.signature,
          })
          .then((response) => {
            if (response.access) {
              const oldRedirect = this.$auth.options.redirect
              this.$auth.options.redirect = false
              this.$auth.setUserToken(response.access, response.refresh)
              this.$auth.options.rewriteRedirects = oldRedirect
              this.step = 3
            } else {
              this.$auth.setUserToken(undefined, undefined)
            }
          })
          .catch(() => {
            this.random = Math.random()
            this.$v.code.$reset()
            this.code = ''
          })
      }
      this.pending = false
    },
    async submitThirdStage() {
      if (this.pending) return
      this.pending = true
      this.$v.password.$touch()
      this.$v.repassword.$touch()
      if (this.$v.repassword.$anyError && !this.$v.password.$anyError) {
        this.$toast.warning(this.$t('auth_reset.passwords_same'))
      } else if (!this.$v.password.$anyError && !this.$v.repassword.$anyError) {
        await this.$axios
          .$put(
            'auth/password/reset/change/',
            {
              new_password: this.password,
              password_confirm: this.repassword,
            },
            {
              headers: {
                Authorization:
                  this.$axios.defaults.headers.common.Authorization,
              },
            }
          )
          .then((response) => {
            if (this.refreshAfterLogin) {
              window.location.reload()
            }
            if (response.access) {
              this.$auth.setUserToken(response.access, response.refresh)
              this.$auth.fetchUser()
            } else {
              this.$auth.setUserToken(undefined, undefined)
            }
            this.$router.push('/auth/login')
            this.$toast.success(this.response.data.detail)
          })
          .catch(() => {})
      }
      this.pending = false
    },
    onChange(v) {
      this.code = v
    },
    onComplete(v) {
      this.code = v
      if (v.length === 6) {
        this.submitSecondStage()
      }
    },
    resend() {
      this.timer = 60
      this.submitFirstStage()
      this.countDownTimer()
    },
    countDownTimer() {
      if (this.timer > 0) {
        setTimeout(() => {
          this.timer -= 1
          this.countDownTimer()
        }, 1000)
      }
    },
  },
}
