//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const KEY_CODE = {
  backspace: 8,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
}
export default {
  name: 'CodeInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    backError: Boolean,
    type: {
      type: String,
      default: 'number',
    },
    hasError: {
      type: Boolean,
      default: true,
    },
    className: {
      type: String,
      default: '',
    },
    fields: {
      type: Number,
      default: 6,
    },
    fieldWidth: {
      type: Number,
      default: 44,
    },
    fieldHeight: {
      type: Number,
      default: 50,
    },
    autoFocus: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    change: {
      type: Function,
      default: () => {},
    },
    complete: {
      type: Function,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const { fields, values } = this
    let vals
    let autoFocusIndex = 0
    if (values && values.length) {
      vals = []
      for (let i = 0; i < fields; i++) {
        vals.push(values[i] || '')
      }
      autoFocusIndex = values.length >= fields ? 0 : values.length
    } else {
      vals = Array(fields).fill('')
    }
    this.iRefs = []
    for (let i = 0; i < fields; i++) {
      this.iRefs.push(`input_${i}`)
    }
    this.id = +new Date()
    return {
      timer: false,
      values: vals,
      autoFocusIndex,
      ArrayOfValues: [],
    }
  },
  mounted() {
    this.$refs.input_0[0].focus()
    for (let i = 0; i < this.values.length; i++) {
      this.ArrayOfValues.push({ value: '' })
    }
    const firstInput = this.$refs?.input_0[0]
    firstInput.addEventListener('paste', async (el) => {
      const promise = await navigator.clipboard.readText()
      const num = promise
      if (!isNaN(num)) {
        // get all the inputs
        // and set the value
        const inputs = this.$refs
        const stringNums = num.toString()
        let i = 0
        for (const [, value] of Object.entries(inputs)) {
          if (stringNums[i]) {
            value[0].value = stringNums[i]
            i++
          }
        }
        if (stringNums.length >= 6) {
          this.$emit('complete', stringNums.slice(0, 6))
        }
      }
    })
  },
  methods: {
    onFocus(e) {
      this.timer = setInterval(function () {
        e.target.placeholder = e.target.placeholder ? '' : '|'
      }, 500)
      e.target.select(e)
    },
    onBlur(e) {
      clearInterval(this.timer)
      e.target.placeholder = ''
    },
    onValueChange(e) {
      const index = parseInt(e.target.dataset.id)
      const { type, fields } = this
      if (type === 'number') {
        e.target.value = e.target.value.replace(/[^\d]/gi, '')
      }
      // this.handleKeys[index] = false;
      if (
        e.target.value === '' ||
        (type === 'number' && !e.target.validity.valid)
      ) {
        return
      }
      let next
      const value = e.target.value
      let { values } = this
      values = Object.assign([], values)
      if (value.length > 1) {
        let nextIndex = value.length + index - 1
        if (nextIndex >= fields) {
          nextIndex = fields - 1
        }
        next = this.iRefs[nextIndex]
        const split = value.split('')
        split.forEach((item, i) => {
          const cursor = index + i
          if (cursor < fields) {
            values[cursor] = item
          }
        })
        this.values = values
      } else {
        next = this.iRefs[index + 1]
        values[index] = value
        this.values = values
      }
      if (next) {
        const element = this.$refs[next][0]
        element.focus()
        element.select()
      }
      this.triggerChange(values)
      // eslint-disable-next-line eqeqeq
      // if target has a value then set the value to the array else set the value to empty string

      const i = e.target.getAttribute('datavage')
      const myInput = e.target
      if (myInput && myInput.value) {
        this.ArrayOfValues[i] = { value: myInput.value }
      }
    },
    onKeyDown(e) {
      const index = parseInt(e.target.dataset.id)
      const prevIndex = index - 1
      const nextIndex = index + 1
      const prev = this.iRefs[prevIndex]
      const next = this.iRefs[nextIndex]
      const i = e.target.getAttribute('datavage')
      // const myInput = e.target;
      switch (e.keyCode) {
        case KEY_CODE.backspace: {
          e.preventDefault()
          const vals = [...this.values]
          if (this.values[index]) {
            vals[index] = ''
            this.values = vals
            this.triggerChange(vals)
            this.ArrayOfValues[i] = { value: '' }
          } else if (prev) {
            vals[prevIndex] = ''
            this.$refs[prev][0].focus()
            this.values = vals
            this.triggerChange(vals)
            this.ArrayOfValues[prevIndex] = { value: '' }
          }
          break
        }
        case KEY_CODE.left:
          e.preventDefault()
          if (prev) {
            this.$refs[prev][0].focus()
          }
          break
        case KEY_CODE.right:
          e.preventDefault()
          if (next) {
            this.$refs[next][0].focus()
          }
          break
        case KEY_CODE.up:
        case KEY_CODE.down:
          e.preventDefault()
          break
        default:
          // this.handleKeys[index] = true;
          break
      }
    },
    triggerChange(values = this.values) {
      const { fields } = this
      const val = values.join('')
      this.$emit('change', val)
      if (val.length >= fields) {
        this.$emit('complete', val)
      }
    },
  },
}
